<template>
  <div class="center-add-teacher">
    <div class="vx-col  w-auto">
      <vx-card>
        <div class="w-full md:w-1/2">
          <div class="vx-col mt-5 vs-con-loading__container">
            <template v-if="logo">
              <!-- Image Container -->
              <div class="img-container w-64  flex items-center">
                <img :src="logo" alt="img" class="responsive">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex mt-5">
                <input type="file" class="hidden" ref="updateImgInput" @change="updateCurrImg" accept="image/*">
                <vs-button type="border" class="mr-4 btn-blue-border" @click="$refs.updateImgInput.click()">Update
                  Photo
                </vs-button>
                <vs-button color="danger" @click="logo = null">Remove Photo</vs-button>
              </div>
            </template>

            <div class="upload-img" v-if="!logo">
              <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*">
              <vs-button type="border" class="btn-blue-border" @click="$refs.uploadImgInput.click()">Upload Photo
              </vs-button>
            </div>
          </div>
          <!-- Room -->
          <div class="vx-col w-full mt-5">
            <label class="block mb-2">Room</label>
            <v-select :options="roomOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="roomFilter"
                      class="mb-4 md:mb-0" data-vv-as="Room"/>
          </div>

          <!-- Title -->
          <div class="vx-col w-full mt-5">
            <label class="block mb-2">Title</label>
            <v-select :options="titleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="titleFilter"
                      class="mb-4 md:mb-0" data-vv-as="Title"/>
          </div>
          <div class="vx-col w-full mt-5">

            <ul class="centerx">
              <li style="display: inline-block; margin-right: 10px">
                <vs-radio v-model="role" vs-value="teacher">Teacher</vs-radio>
              </li>
              <li style="display: inline-block">
                <vs-radio v-model="role" vs-value="leadTeacher">Lead Teacher</vs-radio>
              </li>
            </ul>
          </div>


          <vs-input label="Full Name" v-model="fullName" class="mt-5 w-full" name="fullName" v-validate="'required'"
                    data-vv-as="Full Name"/>
          <span class="text-danger text-sm" v-show="errors.has('fullName')">{{ errors.first('fullName') }}</span>

          <vs-input class="w-full mt-5" v-model="email" name="email" v-validate="'required|email'" label="Email"
                    data-vv-as="Email"/>
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>


          <vs-input label="Mobile Number" v-model="contactNumber" class="mt-5 w-full" name="contactNumber"
                    v-validate="'required|numeric'"
                    data-vv-as="Mobile Number"/>
          <span class="text-danger text-sm" v-show="errors.has('contactNumber')">{{
              errors.first('contactNumber')
            }}</span>
          <div class="vx-col w-full mt-5">
            <label class="block mb-2">Country</label>
            <v-select :options="countryOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="countryFilter" class="mb-4 md:mb-0" data-vv-as="Country"/>
          </div>

          <div class="vx-col w-full mt-5">
            <label class="block mb-2">State</label>
            <v-select :options="stateOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="stateFilter"
                      class="mb-4 md:mb-0" data-vv-as="State"/>
          </div>
          <vs-input label="Street Address" v-model="streetAddress" class="mt-5 w-full" name="streetAddress"
                    data-vv-as="Street Address"/>
          <span class="text-danger text-sm" v-show="errors.has('streetAddress')">{{
              errors.first('streetAddress')
            }}</span>

          <vs-input label="Town/City" v-model="town" class="mt-5 w-full" name="town" data-vv-as="Town"/>
          <span class="text-danger text-sm" v-show="errors.has('town')">{{ errors.first('town') }}</span>
          <vs-input label="Postcode" v-model="postCode" class="mt-5 w-full" name="postCode" data-vv-as="Post Code"/>
          <span class="text-danger text-sm" v-show="errors.has('postCode')">{{ errors.first('postCode') }}</span>

          <label class="vs-input--label mt-5">Account Status</label>
          <vs-switch v-model="status"/>
          {{ status ? 'Active' : 'Inactive' }}
        </div>

        <div class="w-full md:w-1/2 mt-5">
          <div class="flex flex-wrap items-center">
            <vs-button @click="submitData" :disabled="!isFormValid">Save</vs-button>
            <vs-button class="ml-auto" color="danger" type="filled" @click="cancelBtn">Cancel</vs-button>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import {mapActions} from "vuex";

export default {
  data() {
    return {
      centerId: this.$route.params.centerId,
      dataId: null,
      directorId: "",
      titleOptions: [
        {label: 'Mr.', value: 'Mr.'},
        {label: 'Mrs.', value: 'Mrs.'},
        {label: 'Miss.', value: 'Miss.'}
      ],
      fullName: "",
      role: "teacher",
      contactNumber: "",
      email: "",
      logo: null,
      image: null,
      settings: { // perfect scrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
      streetAddress: "",
      town: "",
      postCode: "",
      status: true,
      roomOptions: [],
      countryOptions: [],
      stateOptions: [],
      roomFilter: {label: 'Select room', value: ''},
      titleFilter: {label: 'Select Title', value: ''},
      countryFilter: {label: 'All', value: 'all'},
      stateFilter: {label: 'All', value: 'all'},
      customError: "",
      learningCenterId: null,
    }
  },
  watch: {
    countryFilter(obj) {
      this.stateOptions = obj.states;
    },
  },
  computed: {
    isFormValid() {
      return !this.errors.any()
        && this.fullName
        && this.email
    },
    centerAdmin() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions("center", [
      "updateDirectorTeacher",
      "imageUpload",
      "getDirectorAllCountries",
      "getLearningCenterRooms",
    ]),
    ...mapActions("centerAdmin", [
      "addCenterAdminTeacher"
    ]),
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.image = input.target.files[0];
        var reader = new FileReader()
        reader.onload = e => {
          this.logo = e.target.result
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    showMessage(title, message, color) {
      this.$vs.notify({
        title: title,
        text: message,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color,
      });
    },
    async submitData() {
      await this.$validator.validateAll().then(result => {
        if (result) {
          let data = new FormData();
          data.append('id', this.dataId);
          data.append('room', this.roomFilter.value);
          data.append('title', this.titleFilter.value);
          data.append('fullName', this.fullName);
          data.append('contactNumber', this.contactNumber);
          data.append('streetAddress', this.streetAddress);
          data.append('town', this.town);
          data.append('postCode', this.postCode);
          data.append('state', this.stateFilter.value);
          data.append('email', this.email);
          data.append('logo', this.image);
          data.append('learningCenterId', this.centerAdmin.learningCenterId);
          data.append("directorId", this.centerAdmin.learningCenter.directorId);
          data.append('role', this.role);
          data.append('status', this.status);

          this.customError = "";
          if (this.dataId !== null) {
            this.$vs.loading()
            this.updateDirectorTeacher(data)
              .then((res) => {
                if (res.status === 200) {
                  this.showMessage("Success", "Teacher Updated successfully.", "success");
                  data.country = this.countryFilter.value;
                  this.$emit('refreshData', true);
                }
                this.$vs.loading.close()
              })
              .catch(err => {
                this.$vs.loading.close()
              })
          } else {

            this.$vs.loading();
            this.addCenterAdminTeacher(data).then((res) => {
              this.$vs.loading.close();
              this.showMessage("Success", "Teacher added successfully.", "success");
              this.$router.push('/center-admin/dashboard');
            })
              .catch(err => {
                this.$vs.loading.close()
                if (err.response.status === 422) {
                  this.errors.add({
                    field: "email",
                    msg: err.response.data.message
                  });
                } else {
                  this.$vs.notify({
                    title: 'Failure',
                    text: "Can not add teacher",
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                  })
                }
              })
          }
        }
      })
    },
    cancelBtn() {
      this.$router.push('/center-admin/dashboard');
    },
  },
  created() {
    this.learningCenterId = this.$route.params.centerId;
    this.getDirectorAllCountries()
      .then((res) => {
        this.countryOptions = this.$store.state.center.countryOptions;
      })

    this.getLearningCenterRooms(this.centerId)
      .then((res) => {
        this.roomOptions = res.data.data;
      })
  },
  components: {
    vSelect
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
}
</style>
